<template>
    <div>
        <div class="detail">
            <div class="container" v-if="obj">
                <div class="title">{{obj.name}}</div>
                <div class="time">{{obj.time.replace('T',' ')}}</div>
                <div v-html="obj.content" class="content"></div>
            </div>

            <message v-if="flag" :msg="msg" />
        </div>
        <public-footer />
    </div>
</template>

<script>
    import publicFooter from '@/components/common/PublicFooter'
    import message from '@/components/common/message'

    export default {
        name: "detail",
        components:{
            publicFooter,
            message,
        },
        data(){
            return{
                obj:null,

                flag:false,
                msg:'',
            }
        },
        created(){
            let {aid} = this.$route.params;
            let {pid,id} = this.$route.query;
            this.getDetailInfo(aid,pid,id);
        },
        methods:{
            //获取产品详情
            async getDetailInfo(aid,pid,id){
                try {
                    const {data} = await this.$axios({
                        method:'POST',
                        url:'/api/third/content',
                        data:{
                            first_id:aid,
                            second_id:pid,
                            third_id:id,
                        },
                    });
                    if(data.code){
                        this.obj = data.data;
                    }else{
                        this.msg = data.msg;
                        this.flag = true;
                    }
                }catch (e) {
                    this.msg = '网络错误，请稍后重试';
                    this.flag = true;
                }
            },
        },
        watch:{
            //监听弹出遮罩层
            flag(val){
                if(val){
                    setTimeout(()=>{
                        this.flag = false;
                        this.$router.push({name:'index'});
                    },1000);
                }
            },
            //监听路由参数变化
            $route(val){
                this.getDetailInfo(val.params.aid,val.query.pid,val.query.id);
            }
        },
    }
</script>

<style scoped>
    .detail{
        width: 1000px;
        margin: 0 auto;
        /*background-color: #cccccc;*/
        padding: 30px 0;
        box-sizing: border-box;
    }
    .detail .container{}
    .detail .container .title{
        font-size: 26px;
        font-weight: bold;
        color: #666;
        letter-spacing: 2px;
        margin-bottom: 15px;
    }
    .detail .container .time{
        font-size: 16px;
        color: #666;
        margin-bottom: 40px;
    }
    .detail .container .content{
        color: #666;
        font-size: 16px;
    }
</style>